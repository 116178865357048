<template>
  <div class="elysium">
    <section class="elysium__s1 bg flex-c-v">
      <p class="elysium__s1-subtitle">Paradise Gardens</p>
      <p class="elysium__s1-subtitle">Spring-Summer 2024 Collection</p>
      <h1 class="elysium__s1-title">ELYSIUM</h1>
      <SfButton
        class="elysium__s1-action sf-button--outlined"
        @click="scrollToProducts($refs.productsEl)"
      >Buy</SfButton>
    </section>
    <section class="elysium__s2 bg flex-c-v">
      <p class="elysium__s2-text-wrapper text">The new Elysium collection invites you to stroll through the paradise gardens of Ancient Greece, filled with lush greenery, vibrant flowers, stunning mountain landscapes, and white marble sculptures.</p>
      <SfVideo
        class="elysium__s2-video"
        button-color="white"
        :src="require('~/static/elysium/elysium.mp4')"
        :poster="require('~/static/elysium/s2-p.webp')"
        :muted="false"
      />
    </section>
    <section class="elysium__s3 flex-c-v">
      <p class="elysium__s3-text-wrapper text">Antiquity in the modern world represents admiration for beauty and a quest for both external and internal harmony. In 2024, ancient motifs have become a trend in interior design, textile decor, and European fashion house collections.</p>
      <img
        class="elysium__s3-image"
        :src="require('~/static/elysium/s3-pillow.webp')"
        alt="Elysium"
      />
    </section>
    <section class="elysium__s4 flex-c-v">
      <p class="elysium__s4-text-wrapper text">The highlight of the collection is the Elysium bedding set that is made from soft, silky SENSOTEX® fabric with an enhanced density of 400TC. The print of this bedding set celebrates the nature of Ancient Greece. It features blue-turquoise shades, lilac, and vibrant pink colors. This set is reversible. It has a monochrome sepia-toned pattern on the other side. This allows you to change the atmosphere in your bedroom by flipping the bedding and making the interior palette less saturated if needed.</p>
      <img
        class="elysium__s4-image"
        :src="require('~/static/elysium/s4-duvet.webp')"
        alt="Elysium"
      />
    </section>
    <section class="elysium__s5 grid">
      <div class="col-12 col-m-5">
        <p class="elysium__s5-text-wrapper text">The Elysium collection also includes:</p>
        <ul class="elysium__s5-list">
          <li class="elysium__s5-list-item">A comforter and throw pillows featuring beautiful ancient landscapes</li>
          <li class="elysium__s5-list-item">Solid velvet curtains and bedspreads</li>
          <li class="elysium__s5-list-item">Elegant and comfortable homewear: warm robes, flowing kimonos, and sophisticated pajamas</li>
          <li class="elysium__s5-list-item">Dining textiles and exquisite tableware with elements of ancient landscapes</li>
          <li class="elysium__s5-list-item">Accessories and decorative items for your living room, bedroom, and bathroom</li>
        </ul>
      </div>
      <div class="col-m-1 desktop-only" />
      <div class="col-12 col-m-6">
        <picture>
          <source media="screen and (min-width: 1024px)" :srcset="require('~/static/elysium/s5-d.webp')" />
          <img
            class="elysium__s5-image img-block"
            :src="require('~/static/elysium/s5-m.webp')"
            alt="Elysium"
          />
        </picture>
      </div>
    </section>
    <section class="elysium__s6 flex-c-v">
      <img
        class="elysium__s6-image img-block"
        :src="require('~/static/elysium/sensotex_logo.webp')"
        alt="Elysium"
      />
      <p class="elysium__s6-text-wrapper">The silky SENSOTEX® fabric is a unique development by Togas. This material provides comfort year-round because it is made from natural eucalyptus fibers and infused with Kefalis solution from Ionian seaweed extract for extra softness. This fabric provides you with a light cooling sensation in hot weather. SENSOTEX® is delicate and soft, and it is designed for people with sensitive skin.</p>
    </section>
    <section class="elysium__s7" v-if="products.length" ref="productsEl">
      <h2
        class="elysium__s7-title"
      >Discover the Exquisite Beauty of Textiles and Accessories from the Elysium Collection</h2>
      <div class="elysium__s7-grid grid">
        <SfProductCard
          class="col-6 col-m-3"
          v-for="(product, i) in products" :key="i"
          :image="getMagentoImage(getOptimizedProductThumbnailImage(product))"
          :is-in-wishlist="isInWishlist({ product })"
          :link="localePath(getProductPath(product))"
          :regular-price="$fc(getPrice(product).regular)"
          :special-price="
              getPrice(product).special &&
                $fc(getPrice(product).special)
            "
          :title="getName(product)"
          :tag_line="getTagLine(product)"
          :show-add-to-cart-button="true"
          image-height="240"
          image-tag="img"
          image-width="240"
          @click:wishlist="addItemToWishlist(product)"
        />
      </div>
    </section>
    <section class="elysium__s8">
      <div ref="gallerySlider" class="swiper-container js-gallery">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="slide-01-grid slide-grid">
                <div class="slide-01-grid__img-01">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/01.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/01.webp')"
                  >
                </div>
                <div class="slide-01-grid__img-02">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/02.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/02.webp')"
                  >
                </div>
                <div class="slide-01-grid__img-03">
                  <img
                    class="gallery-img" alt="Gallery image"
                     v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/03.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/03.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-04">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/04.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/04.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-05">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/05.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/05.webp')"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slide-02-grid slide-grid">
                <div class="slide-02-grid__img-06">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/06.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/06.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-07">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/07.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/07.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-08">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/08.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/08.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-09">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/09.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/09.webp')"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slide-01-grid slide-grid">
                <div class="slide-01-grid__img-01">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/10.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/10.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-02">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/11.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/11.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-03">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/12.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/12.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-04">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/13.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/13.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-05">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/14.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/14.webp')"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slide-02-grid slide-grid">
                <div class="slide-02-grid__img-06">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/15.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/15.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-07">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/16.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/16.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-08">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/17.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/17.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-09">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/18.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/18.webp')"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slide-01-grid slide-grid">
                <div class="slide-01-grid__img-01">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/19.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/19.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-02">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/20.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/20.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-03">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/21.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/21.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-04">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/22.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/22.webp')"
                  />
                </div>
                <div class="slide-01-grid__img-05">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/23.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/23.webp')"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="slide-02-grid slide-grid">
                <div class="slide-02-grid__img-06">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/24.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/24.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-07">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/25.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/25.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-08">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/26.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/26.webp')"
                  />
                </div>
                <div class="slide-02-grid__img-09">
                  <img
                    class="gallery-img" alt="Gallery image"
                    v-img="{ group: true, name: 'gallery', src: require('~/static/elysium/gallery/original/27.webp') }"
                    :src="require('~/static/elysium/gallery/thumbs/27.webp')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
    </section>
    <section class="elysium__s9 flex-c-v">
      <p class="elysium__s9-text-wrapper">The Elysium collection will fill your interior with the picturesque nature of Ancient Greece, and immerse you in the aesthetics of Hellenic gardens. Admire the stunning Greek architecture and paintings with Togas.</p>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  useFetch,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import {
  SfButton,
  SfLink,
  SfVideo,
  SfProductCard,
} from '~/components';
import { useFacet, useImage, useProduct, useUiNotification, useUser, useWishlist } from '~/composables';
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import {
  getOptimizedProductThumbnailImage,
  getPrice,
  getName,
  getTagLine,
} from '~/modules/catalog/product/getters/productGetters';

import Swiper, { Pagination } from "swiper";
Swiper.use([Pagination]);

import Vue from 'vue';
import VueImg from 'v-img';

Vue.use(VueImg);

export default defineComponent({
  name: 'Elysium',
  components: {
    SfButton,
    SfLink,
    SfVideo,
    SfProductCard
  },
  head() {
    return {
      title: 'Elysium'
    }
  },
  setup(_, { refs }) {
    const { app } = useContext();
    const { result, search } = useFacet();
    const { getProductPath } = useProduct();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { getMagentoImage, imageSizes } = useImage();
    const { isAuthenticated } = useUser();
    const { send: sendNotification } = useUiNotification();
    const router = useRouter();
    const route = useRoute();

    const loading = ref(false);
    const isMounted = ref(false);
    const products = ref([]);

    useFetch(async () => {
      try {
        loading.value = true;

        const categories = await useCategoryStore();
        if (categories.categories === null) await categories.load();
        const elysiumCategoryUid = categories.categories.children
          .filter(item => item.name ==='Elysium')[0].uid;

        await search({ category_uid: elysiumCategoryUid });
        products.value = facetGetters.getProducts(result.value) ?? [];

      } catch (error) {
        console.error('Error in Elysium.vue: ', error);
      } finally {
        loading.value = false;
      }
    });

    onMounted(() => isMounted.value = true);
    watch([isMounted, loading], () => {
      if (isMounted.value && !loading.value) nextTick(() => initSwiper());
    });

    const gallerySlider = ref(null);
    const initSwiper = () => {
      gallerySlider.value = new Swiper(refs.gallerySlider, {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 4,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
          }
        },
        spaceBetween: 1,
        breakpoints: {
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        },
      });
    };

    const addItemToWishlist = async (product) => {
      if (isAuthenticated.value) {
        await addOrRemoveItem({ product });
      } else {
        await router.push(app.localeRoute({ name: 'customer-login-register', query: { redirect: route.value.path } }));
        sendNotification({
          id: Symbol('need_to_login'),
          message: app.i18n.tc('Need to login message'),
          type: 'warning',
          icon: 'check',
          persist: false,
        });
      }
    };

    const scrollToProducts = (el) => {
      window.scrollTo({
        top: el.offsetTop - document.querySelector('header').offsetHeight,
        behavior: 'smooth'
      });
    };

    return {
      products,
      getOptimizedProductThumbnailImage,
      getPrice,
      getName,
      getTagLine,
      getProductPath,
      isInWishlist,
      addItemToWishlist,
      getMagentoImage,
      imageSizes,
      scrollToProducts,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~swiper/swiper.scss";

.elysium {

  .bg {
    background-size: cover;
  }

  .flex-c-v {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__s1 {
    background-image: url("~/static/elysium/s1-m.webp");
    aspect-ratio: 375 / 700;
    padding: 64px 50px;

    &-title {
      color: var(--togas-white-color);
      font: var(--font-weight--bold) var(--font-size--xl) / var(--line-height--xl) var(--font-family--secondary);
      text-align: center;
      margin-bottom: var(--spacer-base);
    }

    &-subtitle {
      color: var(--togas-white-color);
      font: var(--font-weight--bold) var(--font-size--lg) / var(--line-height--lg) var(--font-family--secondary);
      text-align: center;
      margin-bottom: var(--spacer-xs);
    }

    &-action {
      --button-color: var(--togas-white-color);
      --button-border-color: var(--togas-white-color);
      --button-font: var(--font-weight--medium) var(--font-size--lg) / var(--line-height--lg) var(--font-family--primary);
      --button-height: 40px;
      --button-width: 142px;
      margin: 0 auto;
    }

  }

  &__s2 {
    background-image: url("~/static/elysium/s2-m.webp");

    &-text-wrapper {
      text-align: center;
      width: 344px;
      padding: 11% 32px 16px;
    }

    &-video {
      --video-icon-height: 32px;
      --video-icon-width: 32px;
    }
  }

  &__s3 {
    aspect-ratio: 375 / 352;
    background-image: url("~/static/elysium/s3-m.webp");
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9% 16px 0;

    &-image {
      width: 77%;
      margin: auto 0 16px;
    }

    &-text-wrapper {
      width: 344px;
    }
  }

  &__s4 {
    aspect-ratio: 375 / 482;
    background-image: url("~/static/elysium/s4-m.webp");
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9% 16px 0;
    margin-bottom: 32px;

    &-image {
      width: 77%;
      margin: auto 0 16px;
    }

    &-text-wrapper {
      width: 344px;
    }
  }

  &__s5 {
    padding: 0 20px;
    margin-bottom: 32px;

    &-text-wrapper {
      text-align: left;
      margin-bottom: 16px;
    }

    &-list-item {
      margin: 0 0 8px 24px;
    }

    &-image {
      margin-top: 32px;
    }
  }

  &__s6 {
    aspect-ratio: 375 / 805;
    background-image: url("~/static/elysium/s6-m.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 22% 16px;
    margin-bottom: 32px;

    &-image {
      width: 86px;
      margin-bottom: 20px;
    }

    &-text-wrapper {
      color: var(--togas-white-color);
      font: var(--font-size--sm) / var(--line-height--xs) var(--font-family--primary);
      text-align: center;
      width: 344px;
    }
  }

  &__s7 {
    padding: 0 var(--spacer-sm);
    margin-bottom: var(--spacer-lg);

    &-title {
      font: var(--font-weight--bold) var(--font-size--xl) / var(--line-height--lg) var(--font-family--secondary);
      text-align: center;
      margin-bottom: 32px;
    }

    &-grid {
      --grid-column-gap: var(--spacer-2xs);
    }
  }

  &__s8 {
    position: relative;
    padding: 0 var(--spacer-base);
    margin-bottom: 16px;

    .swiper-pagination {
      margin-top: 8px;
    }

    .slide-grid {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1px;
    }

    .gallery-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .slide-01-grid {
      &__img-01 {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 2;
      }

      &__img-02 {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 2;
      }

      &__img-03 {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-column-end: 4;
      }

      &__img-04 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 3;
      }

      &__img-05 {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-row-end: 5;
        grid-column-end: 4;
      }
    }

    .slide-02-grid {
      &__img-06 {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 2;
      }

      &__img-07 {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 2;
      }

      &__img-08 {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 2;
      }

      &__img-09 {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 5;
        grid-column-end: 4;
      }
    }
  }

  &__s9 {
    aspect-ratio: 375 / 537;
    background-image: url("~/static/elysium/s9-m.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 86px 16px;

    &-text-wrapper {
      color: var(--togas-white-color);
      font: var(--font-size--sm) / var(--line-height--xs) var(--font-family--primary);
      text-align: center;
      width: 344px;
    }
  }

  @include for-desktop {

    &__s1 {
      background-image: url("~/static/elysium/s1-d.webp");
      aspect-ratio: 1366 / 615;
      padding: 36px;
      justify-content: flex-end;

      &-title {
        font: var(--font-weight--medium) var(--font-size--xxl) / var(--line-height--xxxl) var(--font-family--secondary);
        margin-bottom: var(--spacer-sm);
      }

      &-subtitle {
        font: var(--font-weight--bold) var(--font-size--xl) / var(--line-height--xxl) var(--font-family--secondary);
        margin-bottom: var(--spacer-2xs);
      }

      &-action {
        --button-height: 50px;
        --button-width: 168px;
      }
    }

    &__s2 {
      background-image: url("~/static/elysium/s2-d.webp");
      padding: 80px 40px 0;

      &-text-wrapper {
        width: 632px;
        padding: 0;
        margin-bottom: 74px;
      }

      &-video {
        max-width: 1286px;
      }
    }

    &__s3 {
      aspect-ratio: 1366 / 702;
      background-image: url("~/static/elysium/s3-d.webp");
      padding: 106px 40px 0;

      &-text-wrapper {
        text-align: center;
        width: 632px;
      }

      &-image {
        width: 48%;
        margin-top: auto;
      }
    }

    &__s4 {
      aspect-ratio: 1366 / 772;
      background-image: url("~/static/elysium/s4-d.webp");
      padding: 80px 40px 0;

      &-text-wrapper {
        text-align: center;
        width: 632px;
      }

      &-image {
        width: 48%;
        margin-top: auto;
      }
    }

    &__s5 {
      align-items: center;
      padding: 0 40px;
      margin-bottom: 80px;

      &-text-wrapper {
        margin-bottom: 32px;
      }

      &-list-item {
        margin: 0 0 12px 24px;
      }
    }

    &__s6 {
      aspect-ratio: 1366 / 1888;
      background-image: url("~/static/elysium/s6-d.webp");
      padding: 236px 36px;
      margin-bottom: 80px;

      &-image {
        width: 240px;
        margin-bottom: 80px;
      }

      &-text-wrapper {
        font: var(--font-size--base) / 22px var(--font-family--primary);
        width: 634px;
      }
    }

    &__s7 {
      padding: 0 var(--spacer-sm);
      margin-bottom: var(--spacer-2xl);

      &-title {
        font: var(--font-weight--medium) var(--font-size--xxl) / var(--line-height--xxxl) var(--font-family--secondary);
        max-width: 850px;
        margin: 0 auto 40px;
      }

      &-grid {
        --grid-column-gap: var(--spacer-base);
      }
    }

    &__s8 {
      padding: 0;
      margin-bottom: 64px;

      .swiper-pagination {
        margin-top: 32px;
      }

      .gallery-img:not(:hover) {
        filter: brightness(.7);
        transition: all .5s ease-in-out;
      }

    }

    &__s9 {
      aspect-ratio: 1366 / 480;
      background-image: url("~/static/elysium/s9-d.webp");
      padding: 65px 36px;

      &-text-wrapper {
        font: var(--font-size--base) / 22px var(--font-family--primary);
        width: 416px;
      }
    }
  }
}
</style>
